import DermaDots from "../images/DermaDots.png";

export default function Products() {
  return (
    <div className="Products">
      <section class="bg-white py-8 antialiased dark:bg-gray-950 md:py-16">
        <div class="mx-auto max-w-screen-xl px-4 2xl:px-0">
          <h2 class="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Product Collection:
          </h2>

          <div class="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8 border-t border-gray-700 pt-8">
            <div class="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl">
              <div class="space-y-6">
                <div class="rounded-lg border border-gray-200 bg-slate-950 p-4 shadow-sm dark:border-gray-700 md:p-6">
                  <div class="space-y-4 md:flex md:items-center md:justify-evenly md:gap-6 md:space-y-0">
                    <a
                      href="https://amzn.eu/d/dDWmBWs"
                      class="shrink-0 md:order-1 flex items-center justify-center bg-white"
                    >
                      <img
                        class="h-20 w-20 dark:hidden"
                        src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front.svg"
                        alt="imac image"
                      />
                      <img
                        class="hidden h-40 w-40 object-contain bg-white dark:block"
                        src={DermaDots}
                        alt="imac image"
                      />
                    </a>

                    <div class="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                      <a
                        href="https://amzn.eu/d/dDWmBWs"
                        class="text-base font-medium text-gray-900 hover:underline dark:text-white"
                      >
                        DermaDots Acne Patches - Pack of 48 Translucent
                        Hydrocolloid Pimple Patch Spot Treatment Stickers with
                        Tea Tree Oil & Salicylic Acid for Face and Body -
                        Fast-Acting, Vegan & Cruelty Free Skin Care
                      </a>
                    </div>
                  </div>
                </div>

                <div class="rounded-lg border border-gray-200 bg-slate-950 p-4 shadow-sm dark:border-gray-700 md:p-6">
                  <div class="space-y-4 md:flex md:items-center md:justify-evenly md:gap-6 md:space-y-0">
                    <a
                      href=""
                      class="shrink-0 md:order-1 flex items-center justify-center bg-white h-40 md:w-40 object-contain"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="currentColor"
                        class="text-gray-500"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z" />
                      </svg>
                    </a>

                    <div class="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                      <a
                        href=""
                        class="text-base font-medium text-gray-900 hover:underline dark:text-white"
                      >
                        Tallow Moisture - Tallow Moisturiser (1x 50ml), Face
                        Moisturiser to Instantly Soothe and Replenish the Skin's
                        Barrier, Intensely Nourishing Cream, Sensitive Skin,
                        With Vitamin E
                      </a>
                    </div>
                  </div>
                </div>

                <div class="rounded-lg border border-gray-200 bg-slate-950 p-4 shadow-sm dark:border-gray-700 md:p-6">
                  <div class="space-y-4 md:flex md:items-center md:justify-evenly md:gap-6 md:space-y-0">
                    <a
                      href=""
                      class="shrink-0 md:order-1 flex items-center justify-center bg-white h-40 md:w-40 object-contain"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="currentColor"
                        class="text-gray-500"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z" />
                      </svg>
                      {/* <img class="hidden grayscale h-40 w-40 object-contain bg-white dark:block" src="https://m.media-amazon.com/images/I/712RY6UFoKL.jpg" alt="imac image" /> */}
                    </a>

                    <div class="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                      <a
                        href=""
                        class="text-base font-medium text-gray-900 hover:underline dark:text-white"
                      >
                        Tallow Moisture (+ Honey) - Tallow Moisturiser (1x
                        50ml), Face Moisturiser to Instantly Soothe and
                        Replenish the Skin's Barrier, Intensely Nourishing
                        Cream, Sensitive Skin, With Honey & Vitamin E
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
              <div class="space-y-4 rounded-lg border border-gray-200 bg-slate-950 p-4 shadow-sm sm:p-6">
                <p class="text-xl font-semibold text-gray-900 dark:text-white flex justify-center">
                  More products coming soon!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
